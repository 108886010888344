//返礼品ページのスライドっぽいの
$(document).on('click', '#imgSection .thumb_img img', function () {
    var img_url = $(this).attr("src");
    $('#imgSection .main_img img').attr('src', img_url);
});


//ギフトコードの入力を制御
$(document).on('click', '#get_address', function () {
    AjaxZip3.zip2addr('yuubin1','yuubin2','pref','addr1','addr2');

    //成功時に実行する処理
    AjaxZip3.onSuccess = function() {
        $('.addr2').focus();
    };

    //失敗時に実行する処理
    AjaxZip3.onFailure = function() {
        alert('郵便番号に該当する住所が見つかりません');
    };

    return false;
});


//ギフトコードの入力を制御
$(document).on('keyup', '#gift_code', function () {
    var _val = $(this).val();

    _val = _val.replace(/[-]{2,}/g, "-").replace(/[^0-9a-zA-Z!-]/g, "");//ハイフン連続入力を禁止
    //$(this).val(_val.replace(/[^0-9a-zA-Z!-]/g, ""));//半角英数とハイフンのみ

    const limit = 19; // コードの文字数(ハイフン込み)
    if (_val.length > limit) {//19文字以上入力されていたら、19文字目以降を削除
        _val = _val.substr(0, limit);
    }
    let before_count = $(this).attr('data-count'),
        now_count = _val.length;

    if (now_count < 4) {//4文字未満の場合処理しない
        $(this).val(_val);
        $(this).attr('data-count', now_count);
        return;
    }

    var ret = new String(),
        _no_hyphen = _val.split("-").join(""); // ハイフン除去

    //console.log(before_count + '->' + now_count);

    var last_char = _val.slice(-1);
    if (last_char === '-') {
        $(this).val(_val);
        return;
    }

    $(this).attr('data-count', now_count);

    let cdn1 = _no_hyphen.match(/.{4}/g);//4文字区切りで配列へ
    for (var i = 0; i < cdn1.length; i++) {
        //console.log( cdn1[i] );
        if (i >= 4) continue;
        ret += cdn1[i];
        if (i < 3) {
            ret += '-';
        }
    }

    let nokori = new String(_no_hyphen.substr(i * 4));
    ret += nokori;

    last_char = ret.slice(-1);
    if (last_char === '-' && before_count >= now_count) {
        ret = ret.slice(0, -1);
    }

    $(this).val(ret);
});